import environment from "environment";
import { parseResponse } from "@fas/ui-framework";
import {
  defaults,
} from "@mtu/contract/lib/openApiClientDefinitions/rest/service/backofficeTenants/client";
import type { operations } from "@mtu/contract/lib/operations/rest/service/backofficeTenants";
import { get } from "lodash";

function parseErrorMessage(p: object) {
  return get(p, "error[0].message", "");
}

defaults.credentials = "include";
defaults.baseUrl = environment.baseUrl;
defaults.fetch = (input: RequestInfo | URL, init?: RequestInit) => fetch(input, init).then(async (r) => {
  if (r.status === 401) {
    window.location.href = `${environment.endpoints.get.loginUrl}?r=${encodeURIComponent(window.location.href)}`;
  }
  if (!r.ok) {
    const b = await r.json();
    b.errorMessage = parseResponse(b) || parseErrorMessage(b);
    return Promise.reject(b);
  }
  return r;
}).catch((e) => {
  e.errorMessage = parseResponse(e) || parseErrorMessage(e);
  return Promise.reject(e);
});

export type Operations = keyof operations;
export * from "./baseApi";

export type ApiErrorType<ErrorField = string> = {
  errorMessage: string,
  message?: string,
  errors: Array<{ field?: ErrorField, message?: string, error?: string }>,
};
