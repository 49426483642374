// @flow
import React from "react";
import environment from "environment";
import {
  Home,
  Computer,
  HowToRegOutlined,
  MailOutline,
  PermMediaOutlined,
  MessageOutlined,
  AssessmentOutlined,
} from "@mui/icons-material";
import type { AppUrl } from "@fas/ui-core/lib/AppNavbar";

import type { AppsMapItem } from "./types/AppHeader.types";

const appsMap: {[key: string]: AppsMapItem } = {
  dmp: { icon: <Home />, text: "Home" },
  jus: { icon: <Computer />, text: "JuS" },
  cpa: { icon: <HowToRegOutlined />, text: "CPA" },
  adsBox: { icon: <PermMediaOutlined />, text: "AdsBox" },
  webPush: { icon: <MessageOutlined />, text: "WebPush" },
  reports: { icon: <AssessmentOutlined />, text: "Reports" },
  crm: { icon: <MailOutline />, text: "CRM" },
};

const appLinks: AppUrl[] = Object.keys(appsMap)
  .filter((key: string): boolean => !!environment.appLinks[key])
  .map((key: string): AppUrl => ({
    ...appsMap[key],
    url: environment.appLinks[key],
  }));

export default appLinks;
