import type React from "react";
import {
  Navigate, Outlet, Route, Routes,
} from "react-router-dom";
import { AccessDenied, Error } from "@fas/ui-core";
import { Suspense } from "react";
import type { Feature } from "../Affiliates/types";
import filterByOperation from "../Affiliates/filterByOperation";
import clearPath from "../Affiliates/utils/clearPath";

type RouterProps = {
  slots: Feature[],
  Layout?: React.FC<{
    tabs: Pick<Feature, "url" | "label">[],
  }>,
  Index?: React.ReactNode,
  NotFound?: React.FC,
}
function DefaultLayout() {
  return <Outlet />;
}
const RouterSlots: React.FC<RouterProps> = ({
  slots,
  Layout = DefaultLayout,
  Index,
  NotFound = Error,
}) => {
  if (slots.length === 0) return null;
  const slotsWithoutDuplicateUrl = Object.values(slots.reduce((acc, feature) => {
    const existingFeature = acc[feature.url];
    if (!existingFeature || !filterByOperation(existingFeature)) {
      acc[feature.url] = feature;
    }
    return acc;
  }, {} as { [key: string]: Feature }))
    .sort((a, b) => slots.indexOf(a) - slots.indexOf(b));

  const indexFeature = slotsWithoutDuplicateUrl.find((feature) => feature.url === "*");
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route element={<Layout tabs={slotsWithoutDuplicateUrl.filter(filterByOperation)} />}>

          {/* eslint-disable-next-line max-len */}
          <Route index element={Index || (indexFeature && <ProtectedRoute feature={indexFeature} />) || <RedirectToFirstAvailableSlot slots={slotsWithoutDuplicateUrl} />} />

          {slotsWithoutDuplicateUrl.map((feature) => (feature.url ? (
            <Route
              key={feature.url}
              path={feature.url}
              element={<ProtectedRoute feature={feature} />}
            />
          ) : null))}

          <Route path="*" element={<NotFound />} />

        </Route>
      </Routes>
    </Suspense>
  );
};

function RedirectToFirstAvailableSlot({ slots }: { slots: Feature[] }) {
  const availableFeatures = slots
    .filter(({ url, operationIds }) => url && filterByOperation({ operationIds }));

  const firstUrl = clearPath(availableFeatures[0]?.url || "");

  return (firstUrl ? <Navigate replace to={firstUrl} /> : <AccessDenied />);
}

function ProtectedRoute({ feature }: { feature: Feature }) {
  return filterByOperation(feature) ? <feature.Component /> : <AccessDenied />;
}

export default RouterSlots;
