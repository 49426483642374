import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { SentryInit } from "@fas/react-scripts/lib/sentry";
import App from "./App";
import { env } from "./env";

if (!env.REACT_APP_DISABLE_SENTRY_AUTO_INIT) {
  const sentry = new SentryInit({ SENTRY_DSN: env.REACT_APP_SENTRY_DSN });
  sentry.init();
}

function prepare(): Promise<void> {
  if (process.env.NODE_ENV !== "production" && process.env.REACT_APP_DEV_SW) {
    // eslint-disable-next-line global-require,@typescript-eslint/no-var-requires
    const { mockApi } = require("./mocks/browser");// we have to use require instead of import because we don't want to add mocks to the build
    return mockApi.makeServer();
  }
  return Promise.resolve();
}

prepare().then(() => {
  ReactDOM.render(
    <App />,
    document.getElementById("root")
  );
});
