/**
 * const tenantByDepartmentMap btoa ({
 *   [btoa("tenantId")]: btoa("department"),
 * });
 */
const tenantByDepartmentMap = Object.fromEntries(Object.entries({
  "YWVk": "YWRzZW1waXJlZGlyZWN0",
  "YWVz": "YWRzZW1waXJlc21hcnRsaW5r",
  "YWRy": "YWRyb21lZGE=",
  "ZGQ=": "cm5kbWVkaWFidXk=",
  "YWR2": "cmVzYWxl",
  "dGFk": "dG9nZXRoZXJhZHM=",
}).map(([k, v]) => [atob(k), atob(v)]));

const departmentByTenantMap = Object.fromEntries(Object.entries(tenantByDepartmentMap).map(([k, v]) => [v, k]));

export default function getDepartmentByTenant(tenantId: string) {
  return tenantByDepartmentMap[tenantId];
}
export function getTenantByDepartment(department: string) {
  return departmentByTenantMap[department];
}
